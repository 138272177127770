<template>
  <v-container fluid>
    <CustomersSectionSearch @search="() => $refs.table.refresh()" />

    <BaseTable
      ref="table"
      title="Elenco Clienti"
      module="customers"
      :headers="headers"
      :actions="actions"
      :exportable="canUser('customers', 'export')"
      sortby="created_at"
    >
      <template v-slot:item.code="{ item }">
        <v-chip label>{{ item.code | placeholder('N/A') }}</v-chip>
      </template>
      <template v-slot:item.common_name="{ item }">
        <router-link
          :to="{ name: 'customers_detail', params: { id: item.id } }"
        >
          <strong>{{ item.common_name }}</strong>
        </router-link>
      </template>
    </BaseTable>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import CustomersSectionSearch from '@components/customers/CustomersSectionSearch.vue'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'CustomersSearch',
  page: {
    title: 'Clienti',
  },
  components: { CustomersSectionSearch, BaseTable },
  data() {
    return {
      headers: [
        {
          text: 'Identificativo',
          value: 'code',
        },
        {
          text: 'Ragione Sociale',
          align: 'start',
          value: 'common_name',
        },
        {
          text: 'Partita IVA',
          value: 'piva_cfisc',
        },
        {
          text: 'Cognome',
          value: 'last_name',
        },
        {
          text: 'Nome',
          value: 'first_name',
        },
        {
          text: 'Città',
          value: 'city',
        },
        {
          text: 'Provincia',
          value: 'province',
        },
        {
          text: 'Inserito',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Scheda Cliente',
          icon: 'mdi-magnify',
          color: 'primary',
          button: true,
          to: (item) => ({ name: 'customers_detail', params: { id: item.id } }),
          onItemCondition: () => this.canUser('customers', 'read'),
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
}
</script>
