<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="common_name"
        type="text"
        name="Ragione Sociale"
        hint="Ricerca per Ragione Sociale"
        dense
        :sm="6"
        :md="3"
        :lg="3"
        :xl="3"
      />

      <FormItem
        v-model="piva_cfisc"
        type="text"
        name="Partita IVA / Cod. Fiscale"
        hint="Ricerca per Partita IVA / Cod. Fiscale"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="email"
        type="text"
        name="Email"
        hint="Ricerca per indirizzo Email"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="mobile_phone_number"
        type="text"
        name="Cellulare"
        hint="Ricerca per numero di cellulare"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="type"
        type="autocomplete"
        :values="types"
        name="Tipo"
        hint="Ricerca per tipo"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="city"
        type="text"
        name="Città"
        hint="Ricerca per Città"
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
        dense
      />

      <FormItem
        v-model="cap"
        type="text"
        name="CAP"
        hint="Ricerca per CAP"
        dense
        :sm="2"
        :md="1"
        :lg="1"
        :xl="1"
      />

      <FormRegions
        v-model="region"
        name="Regioni"
        hint="Ricerca per Regioni"
        multi
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />

      <FormProvince
        v-model="province"
        :values="provinces"
        :regions="region"
        name="Province"
        hint="Ricerca per province"
        multi
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <v-col :cols="12" class="d-none d-md-flex">Utente collegato</v-col>

      <FormItem
        v-model="customer_user_name"
        type="text"
        name="Nome"
        hint="Ricerca per nome utente collegato"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="customer_user_email"
        type="email"
        name="Email"
        hint="Ricerca per email utente collegato"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="customer_user_phone"
        type="text"
        name="Cellulare"
        hint="Ricerca per cellulare utente collegato"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormRegions from '@components/forms/FormRegions.vue'
import FormProvince from '@components/forms/FormProvince.vue'

const { mapFields } = createHelpers({
  getterType: 'customers/getFiltersFields',
  mutationType: 'customers/SET_FILTER_FIELDS',
})

export default {
  name: 'CustomersSectionSearch',
  components: { FormProvince, FormRegions, FormItem, BaseSectionSearch },
  computed: {
    ...mapFields([
      'common_name',
      'piva_cfisc',
      'city',
      'cap',
      'type',
      'province',
      'region',
      'email',
      'mobile_phone_number',
      'work_phone_number',
      'customer_user_name',
      'customer_user_email',
      'customer_user_phone',
    ]),
    ...mapState('customers', ['filters', 'types']),
    ...mapState('utils', ['regions', 'provinces']),
  },
  mounted() {
    this.reset()
  },
  methods: {
    ...mapMutations('customers', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
